/*******************************
         Theme Overrides
*******************************/

/*******************************
            Header
*******************************/

/* Standard */
.ui.header {
  letter-spacing: -0.5px;
  line-height: 1.5;
  font-style: normal;
  font-stretch: normal;
  margin-bottom: 5px;
}

.ui.dividing.header {
  font-size: 24px;
  letter-spacing: -0.5px;
  color: #222222;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.ui.sub.header {
  text-transform: none;
}
