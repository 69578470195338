/*******************************
         Theme Overrides
*******************************/
.ui.menu {
  font-size: @fontSize;
}


.ui.secondary.menu a.item:hover,
.ui.secondary.menu a.item.active,
.ui.secondary.menu a.item.active:hover {
  background: none;
  color: @red !important;
}
