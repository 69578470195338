/*******************************
         Theme Overrides
*******************************/

/*--------------
  Rounded Edges
---------------*/

.ui.cards > .card > :first-child,
.ui.card > :first-child {
  border-radius: @borderRadius !important;
}

/*--------------
     Content
---------------*/

.ui.cards > .card > .content,
.ui.card > .content {
  border-top: none;
  padding: @contentPadding;
}

.ui.cards > .card > .content > .header:not(.ui),
.ui.card > .content > .header:not(.ui) {
  line-height: 1.42;
  margin-top: 0;
  letter-spacing: -0.5px;
}

/*--------------
      Meta
---------------*/

.ui.cards > .card .meta,
.ui.card .meta {
  line-height: 1.22;
  letter-spacing: 0.1px;
}
