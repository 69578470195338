/*******************************
         Theme Overrides
*******************************/
.ui.buttons .button,
.ui.buttons .or,
.ui.button {
  font-size: @fontSize;
}


.ui.primary.button {
  background-image: @primaryBackgroundImage;
}

.ui.secondary.button,
.ui.secondary.buttons .button:hover,
.ui.secondary.button:hover,
.ui.secondary.buttons .button:focus,
.ui.secondary.button:focus,
.ui.secondary.buttons .button:active,
.ui.secondary.button:active,
.ui.secondary.buttons .active.button,
.ui.secondary.buttons .active.button:active,
.ui.secondary.active.button,
.ui.secondary.button .active.button:active {
  background-color: #FAF5F3;
}

.ui.icon.left.labeled.button {
  border-radius: 4px;
}

