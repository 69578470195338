/*******************************
         Theme Overrides
*******************************/

.ui.input {
  font-weight: @bold;
  color: @inputColor;
}

.ui.input > input {
  font-size: @16px;
  font-weight: @bold;
  letter-spacing: -0.3px;
}

.ui.input.error > input {
  color: @inputColor;
}

.ui.input.focus > input,
.ui.input > input:focus  {
  color: @inputColor;
}

.ui.icon.input > i.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
